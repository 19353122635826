import { environment } from "src/environments/environment";

/**
 * Page Slugs. JS Object that contains the page ID of the pages in the backend.
 * The navbar component is based on this object.
 * The key is the api/v2/pages/{id}
 * The Value is what is displayed in the navbar
 */
export const PUBLIC_PAGE_SLUGS: any = {
  vereniging: {
    vereniging: "Vereniging",
    bestuur: "Bestuur",
    botenvloot: "Botenvloot",
    verenigingslied: "Verenigingslied",
  },
  wedstrijd: {
    wedstrijd: "Wedstrijd",
    olympiers: "Olympiërs",
    "wk-gangers": "WK-Gangers",
    "ek-gangers": "EK-Gangers",
    varsity: "Varsity Oude Vier",
    "varsity-dames": "Varsity Dames Vier",
    records: "Records",
  },
  competitie: {
    competitie: "Competitie",
  },
  ser: {
    ser: "SER",
  },
  photoalbums: {
    photoalbums: "Fotoalbums",
  },
  contact: {
    contact: "Contact",
    algemeen: "Algemeen",
    socialeveiligheid: "Sociale veiligheid",
  },
};

export const MEMBER_PAGE_SLUGS: any = {
  algemeen: environment.mamonIntegration? {
    algemeen: "Algemeen",
    verenigingshap: "Verenigingshap",
    ledenlijst: "Ledenlijst",
    downloads: "Downloads",
    declaraties: "Declaraties",
  }:
  {
    algemeen: "Algemeen",
    ledenlijst: "Ledenlijst",
    downloads: "Downloads",
    declaraties: "Declaraties",
  },
  commissies: {
    commissies: "Commissies",
  },
  roeien: {
    roeien: "Roeien",
    competitieploegen: "Competitieploegen",
    wedstrijdploegen: "Wedstrijdploegen",
    ergocalculator: "Ergocalculator",
    // blikkenlijst: "Blikkenlijst",
    // taartenlijst: "Taartenlijst",
    ergometerranglijst: "Ergometerranglijst",
  },
  spammen: {
    spammen: "Spammen",
  },
  frusten: {
    frusten: "Frusten",
  },
  kleding: {
    kleding: "Kleding",
    assortiment: "Assortiment",
    tweedehands: "Tweedehands",
    webshops: "Online webshops",
  }
};
